<template>
  <div id="box">
    <navbar ref="nav" @sidebarMobile="sidebarMobile"></navbar>
    <sidebar
      @clickSidebar="clickSidebar"
      ref="sidebarDesktop"
      class="is-hidden-touch"
    ></sidebar>
    <sidebar
      class="is-hidden-desktop"
      :class="`${openSidebarMobile}`"
      ref="sidebarMobile"
    ></sidebar>
    <main class="hero is-ligth is-fullheight-with-navbar" @click="isFocus">
      <section class="hero-body">
        <div :class="`${vueContainer}`">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </section>
      <!-- Hero footer: will stick at the bottom -->
      <!--  <div class="hero-foot is-dark">
        <footerbar />
      </div> -->
    </main>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Nav/Navbar.vue'
import Sidebar from '@/components/Side/Sidebar.vue'
import Footerbar from '@/components/Footer/Footerbar.vue'

@Component({
  components: {
    Navbar,
    Sidebar,
    Footerbar,
  },
})
export default class BoxLayout extends Vue {
  vueContainer = 'v-container'
  openSidebarMobile = 'is-hidden'
  mounted() {
    this.$refs.nav.navLayout(true)
    //console.log('BoxLayout')
  }
  isFocus() {
    this.openSidebarMobile = 'is-hidden'
    /* this.$refs.sidebarDesktop.sidebar="sidebar"
    this.vueContainer = 'v-container'
    this.$refs.nav.navOpenSidebar(false) */
  }
  clickSidebar(value) {
    this.$refs.nav.navOpenSidebar(value)
    if (value) {
      this.vueContainer = 'sidebarOpen'
    } else {
      this.vueContainer = 'v-container'
    }
  }
  sidebarMobile(value) {
    if (value) {
      this.openSidebarMobile = ''
    } else {
      this.openSidebarMobile = 'is-hidden'
    }
    this.$refs.sidebarMobile.sidebar = 'sidebar open'
  }
}
</script>

<style lang="scss">
/* .fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to  .fade-leave-active below version 2.1.8 {
    opacity: 0;
} */
#box {
  .hero-body {
    padding: 0rem !important;
  }
  .hero.is-halfheight .hero-body,
  .hero.is-fullheight .hero-body,
  .hero.is-fullheight-with-navbar .hero-body {
    align-items: inherit;
    display: flex;
  }
  .hero-foot {
    background-color: #f4f5f7;
  }
}
.v-container {
  width: calc(100% - 4.875rem);
  margin-left: 4.875rem;
  transition: all 0.5s ease;
}
.sidebarOpen {
  width: calc(100% - 15.625rem);
  margin-left: 15.625rem;
  transition: all 0.5s ease;
}
@media (max-width: 420px) {
  .v-container {
    width: 100%;
    margin-left: 0px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 1023px) {
  .v-container {
    width: 100%;
    margin-left: 0px;
    transition: all 0.5s ease;
  }
}
</style>
